<template>
  <b-card>
    Roles 
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      roles: [],
    }
  },
  mounted() {
    this.get_roles()
  },
  methods: {
    get_roles() {
      const { token } = this.$store.state.auth.ActiveUser

      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .get(`/api/roles`)
        .then(response => {
          const output = response.data
          this.roles = output
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the role list. Please try again later!',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>